<template>
	<div class="garden-swiper">
		<!-- <a-table :rowKey="(item,index) => {return index}"
			:loading="dataLoading" 
			:columns="columns"
			:data-source="list"
			:pagination="false"
			:bordered="true">
			<template slot="operation" slot-scope="index, item">
				<div class="control-box">
					<a-button size="small" type="primary" @click="toEdit(item)">编辑</a-button>
				</div>
			</template>
		</a-table> -->
		<div class="ant-table-wrapper">
			<div class="ant-spin-nested-loading">
				<div class="ant-spin-container">
					<div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
						<div class="ant-table-content">
							<div class="ant-table-body">
								<table class="">
									<colgroup>
										<col />
										<col />
										<col style="width: 185px; min-width: 185px;" />
									</colgroup>
									<thead class="ant-table-thead">
										<tr>
											<th key="0" class="">
												<span class="ant-table-header-column">
													<div>
														<span class="ant-table-column-title">排序</span>
													</div>
												</span>
											</th>
											<th key="park_name" class="">
												<span class="ant-table-header-column">
													<div>
														<span class="ant-table-column-title">推荐园区</span>
													</div>
												</span>
											</th>
											<th key="operation" class="ant-table-row-cell-break-word ant-table-row-cell-last">
												<span class="ant-table-header-column">
													<div>
														<span class="ant-table-column-title">操作</span>
													</div>
												</span>
											</th>
										</tr>
									</thead>
									<tbody @end="onEnd" 
										v-model="list" 
										:animation="300" 
										is='vuedraggable' 
										class="ant-table-tbody" 
										style="display: table-row-group;vertical-align: middle;border-color: inherit;">
										<tr class="ant-table-row ant-table-row-level-0" :data-row-key="index" v-for="(item,index) in list" :key="index">
											<td class="">{{index+1}}</td>
											<td class="">{{item.park_name}}</td>
											<td class="ant-table-row-cell-break-word">
												<div class="control-box">
													<button type="button" class="ant-btn ant-btn-primary ant-btn-sm" @click="toEdit(item,index)">
														<span>编 辑</span>
													</button>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<a-modal v-model="swiperModal" title="园区设置" okText="提交" cancelText="取消" @ok="confirmSwiper" :confirmLoading="editIng" @cancel="cancelSwiper">
			<div class="link-box"> <a-select v-model="cur_park" class="a-select" placeholder="请选择" @change="chooseLink">
					<a-select-option :value="item.park_id" v-for="(item, index) in linkList" :key="item.park_id">{{ item.park_name }}</a-select-option>
				</a-select>
			</div>
		</a-modal>
	</div>
</template>
<script>
import vuedraggable from 'vuedraggable';
export default {
	components:{vuedraggable},
	data() {
		let columns = [
			{
				title: '排序',
				customRender: (text, record, index) => index + 1
			},
			{
				title: '推荐园区',
				key: 'park_name',
				dataIndex: 'park_name'
			},
			{
				title: '操作',
				dataIndex: 'operation',
				scopedSlots: { customRender: 'operation' },
				width: '185px'
			}
		];
		return {
			editIng: false,
			dataLoading: false,
			columns,
			swiperModal: false,
			loaded: false,
			linkData: [],
			linkIdArr: [],
			cur_park: '',
			options: [
				{
					value: 'zhejiang',
					label: 'Zhejiang',
					isLeaf: false
				},
				{
					value: 'jiangsu',
					label: 'Jiangsu',
					isLeaf: false
				}
			],
			list: [
				{
					index: 0,
					park_name: '',
					park_id: ''
				},
				{
					index: 1,
					park_name: '',
					park_id: ''
				},
				{
					index: 2,
					park_name: '',
					park_id: ''
				},
				{
					index: 3,
					park_name: '',
					park_id: ''
				}
			],
			page: {
				current: 1,
				total: 0
			},
			uploading: false,
			deling: false,
			linkList: [],
			curInd: 0,
			old: {}
		};
	},
	computed: {},
	async created() {
		let link1 = await this.$post('ParkList', { limit: 999999, is_show: 1 });
		this.linkList = link1.data.list;
		this.loaded = true;
		this.getSwiper();
	},
	mounted() {},
	methods: {
		onEnd(){
			let t = this;
			let content = t.list.map(item => {
				return item.park_id;
			});
			t.$post('IndexConfigParkEdit', { content })
		},
		getSwiper() {
			let t = this;
			t.dataLoading = true;
			t.$post('IndexConfigParkInfo')
				.then(res => {
					let { code, data, msg } = res;
					if (code == 0) {
						for (let i = 0; i < data.length; i++) {
							t.list[i].park_name = data[i].park_name;
							t.list[i].park_id = data[i].park_id;
						}
						console.log('dsada', t.list);
					} else {
						t.$message.error(msg, 1.5);
					}
					t.dataLoading = false;
				})
				.catch(err => {
					t.dataLoading = false;
				});
		},
		onChange(value) {
			console.log(value);
		},
		showAddSwiper() {
			this.swiperModal = true;
		},
		handleChange(res) {
			if (res.file.status == 'uploading') {
				this.uploading = true;
			}
			if (res.file.status == 'done') {
				this.uploading = false;
				this.form.resource_id = res.file.response.data.resource_id;
			}
		},
		linkChange(e) {
			console.log(e);
		},
		deleteImg() {
			this.form.resource_id = '';
		},
		toEdit(item,ind) {
			console.log(item)
			let t = this;
			t.curInd = ind
			this.swiperModal = true;
			this.old = JSON.parse(JSON.stringify(item));
			this.cur_park = this.old.park_id
		},
		getAllLink(selectedOptions) {},
		chooseLink(e) {
			console.log(e);
			let t = this;
			for (let i = 0; i < this.linkList.length; i++) {
				console.log(this.linkList[i].park_id, e);
				if (this.linkList[i].park_id == e) {
					console.log(this.list[t.curInd]);
					this.list[t.curInd].park_id = e;
					this.list[t.curInd].park_name = this.linkList[i].park_name;
				}
			}
		},
		confirmSwiper() {
			let t = this;
			t.editIng = true;
			let content = t.list.map(item => {
				return item.park_id;
			});
			t.$post('IndexConfigParkEdit', { content })
				.then(res => {
					let { code, data, msg } = res;
					t.editIng = false;
					if (code == 0) {
						t.$message.success(msg, 1.5);
						t.swiperModal = false;
						t.getSwiper();
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {
					t.editIng = false;
				});
		},
		cancelSwiper() {
			this.list[this.curInd] = this.old;
		}
	}
};
</script>

<style lang="less"></style>
